.about {
  padding: 100px 0;

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: start;
    gap: 120px;
  }

  &__title {
    font-size: 54px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 2px;
    margin-bottom: 50px;
  }

  &__text {
    width: 50%;
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 2px;
    align-self: center;
  }

  &__image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 976px) {
  .about {
    &__content {
      gap: 20px;
    }
    &__title {
      font-size: 46px;
    }
  }
}

@media (max-width: 768px) {
  .about {
    &__content {
      gap: 40px;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      font-size: 36px;
    }
    &__text {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
    }
    &__image {
      transform: scaleY(-1);
    }
  }
}
