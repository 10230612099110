.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: #fff;
  width: 400px;
  padding: 25px;
  transition: 0.5s all;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    &.active {
      opacity: 1;
      pointer-events: all;
      transition: 0.5s all;
      z-index: 1050;
    }
  }

  &.active {
    transform: translate(-50%, -50%) scale(1);
    transition: 0.5s all;
  }

  &__box {
    position: relative;
    max-width: 800px;
    width: 100%;
    height: 500px;
    background-color: rgb(42, 148, 224);
    display: flex;
    font-size: 36px;
    font-weight: 700px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
  }
}

.close-popup {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  right: 15px;
  top: 10px;
  cursor: pointer;
  transition: all 0.3s;

  path {
    fill: #fff;
  }

  &:hover path {
    fill: #00040e;
    transition: all 0.3s;
  }
}

@media (max-width: 976px) {
  .popup {
    &__box {
      z-index: 1007;
    }
  }
}

@media (max-width: 670px) {
  .popup {
    &__box {
      font-size: 20px;
    }
  }
}