.page-dev {
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    max-width: 900px;
    padding: 20px;
  }
  &__title {
    font-size: 180px;
    color: rgb(42, 148, 224);
  }
  &__subtitle {
    font-size: 38px;
  }
  &__link {
    max-width: 310px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .page-dev { 
 
    &__title {
      font-size: 80px;
    }
    &__subtitle {
      font-size: 22px;
    }
    &__link {
      font-size: 14px;
    }
  }
}
