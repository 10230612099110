.proffer {
  padding: 100px 0;
  background: linear-gradient(
    180deg,
    rgba(0, 148, 255, 0) 13.74%,
    rgba(0, 148, 255, 0.99) 57.252%,
    rgba(0, 148, 255, 0) 100%
  );

  &__title {
    margin-bottom: 100px;
    font-size: 54px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 2px;
  }
}

@media (max-width: 768px) {
  .proffer {
    &__title {
      font-size: 46px;
      line-height: 56px;
    }
  }
}

@media (max-width: 670px) {
  .proffer {
    &__title {
      font-size: 36px;
      line-height: 46px;
    }
  }
}
