 html {
   box-sizing: border-box;
   scroll-behavior: smooth;
 }


 *,
 *::after,
 *::before {
   box-sizing: inherit;
 }

 ul[class],
 ol[class] {
   padding: 0;
 }

 body,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 p,
 ul,
 ol,
 li,
 figure,
 figcaption,
 blockquote,
 dl,
 dd {
   margin: 0;
 }

 ul[class] {
   list-style: none;
 }
 

 a, a[class], a:link, a:visited, a:focus, a:active{
  color: #fff;
  text-decoration:none; 
}

 img {
   max-width: 100%;
   display: block;
 }

 input,
 button,
 textarea,
 select {
   font: inherit;
 }