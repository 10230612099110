.service {
  padding: 100px 0;
  &__title {
    font-size: 64px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 85px;
  }

  &__wrapper {
    width: 100%;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 62px;
    margin-bottom: 160px;
  }

  .last {
    margin-bottom: 0;
  }

  &__item-title {
    font-size: 42px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 1px;
  }

  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 30px;
  }

  &__line {
    display: block;
    height: 4px;
    width: 190px;
    background-color: #2a94e0;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  &__btn {
    margin-top: 10px;
    width: 272px;
    height: 66px;
  }

  &__image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      padding-right: 30px;
    }
  }

  &__img {
    width: 70%;
  }
}

@media (max-width: 976px) {
  .service {
    &__content {
      width: 100%;
    }

    &__item {
      flex-direction: column-reverse;
      margin-bottom: 100px;
      &.last {
        align-content: center;
      }
    }

    &__image {
      &:first-child {
        padding-right: 0px;
        order: 1;
      }
    }
    &__title {
      font-size: 46px;
    }

    &__item-title {
      font-size: 32px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media (max-width: 768px) {
  .service {
    &__image {
      width: 75%;
    }
    &__btn {
      height: 48px;
    }
  }
}

@media (max-width: 670px) {
  .service {
    &__content {
      gap: 15px;
    }

    &__item {
      margin-bottom: 80px;
    }

    &__image {
      width: 75%;
    }
    &__title {
      font-size: 36px;
    }

    &__item-title {
      font-size: 28px;
    }
    &__subtitle {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
