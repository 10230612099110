.btn {
  cursor: pointer;
  border-radius: 36px;
  background: rgb(42, 148, 224);
  padding: 10px 32px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #fff;
  letter-spacing: 2px;
  gap: 20px;
  transition: all 0.3s;
  &:hover {
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
      0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
  }
  &__social {
    width: 310px;
  }
}

.hover-effect,
.hover-effect-2,
.hover-effect svg path {
  transition: fill 0.3s ease, color 0.3s ease;
}

.hover-effect:hover,
.hover-effect:hover svg path {
  fill: #2a94e0;
  color: #2a94e0;
}

.hover-effect-2 rect,
.hover-effect-2 rect path {
  transition: fill 0.3s ease;
}

.hover-effect-2:hover rect {
  fill: #2a94e0;
}

.hover-effect-2:hover rect path {
  fill: #fff;
}
.no-scroll {
  overflow: hidden;
}

.input__error {
  border: 2px solid red;
}
textarea::-webkit-scrollbar {
  display: none;
}
