.header {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
  }

  &__logo {
    padding-left: 10px;
    width: 90px;
    height: 28px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 20px;
  }

  &__title {
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 2px;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 300;
    line-height: 72px;
    letter-spacing: 2px;
  }

  &__menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    line-height: 48px;
    letter-spacing: 2px;
    color: #fff;
    padding-right: 20px;
  }
}

@media (max-width: 976px) {
  .user-nav {
    margin-left: 30px;
    &__link svg {
      width: 20px;
      height: 25px;
    }
  }
  .header {
    &__title {
      font-size: 46px;
      line-height: 46px;
    }
    &__menu {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .hover-effect:hover,
  .hover-effect:hover svg path {
    fill: #000;
    color: #000;
  }
  .header {
    &__wrapper {
      justify-content: flex-start;
      height: 90vh;
    }
    &__content {
      gap: 10px;
    }
    &__title {
      font-size: 36px;
      line-height: 35px;
    }
    &__subtitle {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

@media (max-width: 670px) {
  .header {
    &__logo {
      padding: 0;
      width: 75px;
      height: 28px;
    }
    &__menu {
      padding-right: 0;
    }
  }
}

@media (max-width: 620px) {
  .header {
    &__content {
      padding-top: 60px;
    }
  }
}
