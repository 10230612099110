.not-found {
  height: 100vh;
  background-image: url("../../assets/bg-blur.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    max-width: 670px;
    padding: 20px;
  }
  &__title {
    font-size: 180px;
    color: rgb(42, 148, 224);
  }
  &__subtitle {
    font-size: 38px;
  }
  &__link {
    max-width: 310px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .not-found {
    height: 100vh;
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;
 
    &__title {
      font-size: 80px;
    }
    &__subtitle {
      font-size: 20px;
    }
    &__link {
      font-size: 14px;
    }
  }
}
