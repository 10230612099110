.menu {
  &__btn {
    display: none;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    gap: 50px;
  }

  &__list-link {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .menu {
    &__btn {
      z-index: 1005;
      position: relative;
      width: 30px;
      height: 25px;
      border: none;
      right: 0;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;
      transition: opacity 0.4s ease;

      span {
        width: 100%;
        height: 3px;
        background-color: #fff;
        transition: 0.4s;
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #fff;
        top: 0;
        transition: 0.4s;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #fff;
        bottom: 0;
        transition: 0.4s;
      }
      &.menu__btn-active span {
        opacity: 0;
        transition: 0.4s;
      }

      &.menu__btn-active::after {
        top: 11px;
        transform: rotate(-45deg);
        transition: 0.4s;
      }

      &.menu__btn-active::before {
        top: 11px;
        transform: rotate(45deg);
        transition: 0.4s;
      }
    }

    .menu__list {
      position: fixed;
      background-color: #2a94e0;
      height: 100vh;
      width: 100%;
      max-width: 360px;
      top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0;
      align-items: flex-end;
      z-index: 5;
      transition: all 0.3s;
      padding-right: 35px;
      line-height: 35px;
      right: 0;
      padding-top: 80px;
      right: -400px;
    }
    .menu__list.menu__list__active {
      right: 0;
      z-index: 1004;
    }

    .hover-effect,
    .hover-effect svg path {
      transition: fill 0.3s ease, color 0.3s ease;
    }

    .hover-effect:hover,
    .hover-effect:hover svg path {
      fill: #000000;
      color: #000000;
    }
  }
}
