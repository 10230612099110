.user-nav {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    
    &:nth-child(2) {
      margin-left: 30px;
    }
    svg {
      margin-left: 3px;
    }
  }

  &__link-fixed {
    z-index: 1000 !important;
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    max-width: 100%;
    width: 100px;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    transition: all 0.3s;
    &:hover {
      width: 367px;
    }
    &__link-btn {
      width: 367px;
    }
    &:nth-child(2) {
      margin-left: 30px;
    }
  }
  &__link-btn {
    cursor: pointer;
    border-radius: 36px;
    background: rgb(42, 148, 224);
    padding: 10px 32px;
    border: none;
    width: 310px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    color: #fff;
    letter-spacing: 2px;
    gap: 20px;
    transition: all 0.3s;
    &:hover {
      box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
    }
  }

  &__link-text {
    transform: translateX(-100px);
    width: 240px;
    opacity: 0;
    transition: all 0.3s;
  }
  &__link-btn-fixed {
    height: 58px;
    overflow: hidden;
    width: 100px;
    transition: all 0.7s;

    &:hover {
      transition: all 0.3s;
      width: 367px;
    }
    &:hover .user-nav__link-text {
      opacity: 1;
      transform: translateX(0px);
      transition: all 1s;
    }
  }
}

@media (max-width: 976px) {
  .user-nav {
    margin-left: 30px;
    &__link svg {
      width: 20px;
      height: 25px;
    }
  }
}

@media (max-width: 768px) {
  .user-nav {
    &__link-fixed {
      bottom: 80px;
      right: 20px;
    }
    &__link-fixed {
      transition: all 0.3s;
      width: 70px;
      &:hover {
        width: 367px;
      }
    }

    &__link-btn {
      padding: 10px 20px;
    }
    &__link-btn-fixed {
      height: 48px;
      width: 70px;
    }
  }
}
