@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import "styles/utils/reset"; 
@import "styles/globals";  


.container {
  max-width: 1320px;
  padding: 0 15px;
  margin: 0 auto;
}

body {
  min-height: 100vh;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  background-color: #00040e;
}

.wrapper {
  height: 100%;
  background-image: url("assets/bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
 
@media (max-width: 670px) {
  .wrapper {
    background-position: center 70%;
  }
}

 