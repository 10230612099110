.scroll-button {
  position: fixed;
  z-index: 999;
  bottom: -120px;
  right: 70px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.6s;
  &:hover {
    opacity: 1;
    transition: all 0.6s;
  }
}

.scroll-button.show {
  transition: all 0.6s;
  bottom: 120px;
}

@media (max-width: 768px) {
  .scroll-button {
    right: 40px;
    width: 30px;
    height: 64px;
  }
}
