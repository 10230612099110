.footer {
  padding: 35px 24px 20px;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  &__logo {
    width: 8%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #2a94e0;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.social {
  &__list {
    display: flex;
    justify-content: center;
    gap: 12px;
  }
}

@media (max-width: 1220px) {
  .footer {
    .hover-effect:hover,
    .hover-effect:hover svg path {
      fill: #2a94e0;
      color: #2a94e0;
    }
  }
}

@media (max-width: 976px) {
  .footer {
    font-size: 14px;
    .menu__list {
      gap: 20px;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    &__top {
      flex-direction: column;
      gap: 30px;
    }
  }
}


@media (max-width: 620px) {
  
  .footer {
    font-size: 12px;
    .menu__list {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    &__logo {
      width: 60px;
    }
  }
}

