.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 80px;
}

.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 12px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 12px)
  );
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 12px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 12px)
  );
}

.swiper-slide {
  position: relative;
  width: 360px;
  height: 530px;
  background-color: #ebfafc;
  border-radius: 40px;
  &__item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    height: 470px;
  }

  &__line {
    height: 4px;
    width: 100%;
    background-color: #2a94e0;
  }
  &__content {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    text-align: left;
    width: 100%;
  }

  &__image {
    width: 40%;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 2px;
    text-align: left;
    color: #010b17;
    padding-top: 30px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: justify;
    color: #010b17;
  }

  .swiper-slide__btn {
    border: 1px solid rgb(42, 148, 224);
    border-radius: 36px;
    padding: 10px 32px;
    background: rgb(255, 255, 255);
    color: #010b17;
    max-width: 100%;
    width: 200px;
    align-self: center;
    &:hover {
      background-color: #2a94e0;
      color: #fff;
    }
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.offer__good {
  position: absolute;
  content: "";
  width: 100%;
  max-width: 230px;
  background-color: rgba(253, 169, 9, 0.4);
  border-radius: 0px 0px 50px 50px;
  height: 29px;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 2px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &.promotion__good {
    background: rgba(118, 253, 9, 0.4);
  }
}

@media (max-width: 620px) {
  .swiper-slide {
    width: 300px;
    height: 480px;
  }
  .swiper-slide__item-wrapper {
    padding: 20px;
    height: 440px;
  }

  .swiper-slide__line {
    width: 90%;
  }
}
