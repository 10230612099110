.contact {
  padding: 100px 80px 100px 80px;
  background-color: #0094ff7a;
  transition: all 0.3s;

  &.contacts-page {
    padding: 100px 80px 120px 80px;
    background-color: none;
    background: linear-gradient(
      180deg,
      rgba(0, 148, 255, 0) 13.74%,
      rgb(0 113 195 / 99%) 57.252%,
      rgba(0, 148, 255, 0) 100%
    );
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 130px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 85px;

    &.contacts {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__text {
    display: flex;
    justify-content: space-between;
    gap: 130px;
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 2px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    max-width: 408px;
    width: 100%;
  }

  &__form-input {
    padding: 30px 40px;
    border-radius: 100px;
    border: none;
    width: 100%;
    &--error {
      border: 2px solid #dd4d1b;
    }
  }

  &__form-lable {
    margin-top: 10px;
    text-align: center;
    transition: all 0.3s;
  }

  &__form-textarea {
    border-radius: 30px;
    resize: none;
    width: 100%;
    height: 200px;
  }

  .contact__form-btn {
    padding: 20px 40px;
    max-width: 408px;
    width: 100%;
    border-radius: 100px;
  }
}

@media (max-width: 1220px) {
  .contact {
    padding: 50px 50px 50px 40px;
    &.contacts-page {
      padding: 50px 50px 50px 40px;
    }
    &__content {
      gap: 40px;
    }
    &__wrapper {
      gap: 50px;
    }
    &__text {
      font-size: 20px;
      gap: 40px;
    }
    &__form-input {
      padding: 20px 40px;
    } 
    &__form-btn {
      padding: 20px 40px; 
    }
  }
}

@media (max-width: 976px) {
  .contact {
    padding: 50px 15px 50px 15px;
    &.contacts-page {
      padding: 50px 15px 50px 15px;
    }
    &__text {
      font-size: 18px;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
  .contact {
    padding: 50px 0 50px 0;
    &.contacts-page {
      padding: 50px 0 50px 0;
    }
    &__wrapper {
      gap: 40px;
      flex-direction: column;
      align-items: center;
    }
    .contact__form-btn {
      padding: 16px 36px; 
    }
  }
}

@media (max-width: 620px) {
  .contact {
    &__content {
      flex-direction: column;
      align-items: center;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
  }
}
