.contacts-page {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
  }
}

@media (max-width: 1220px) {
  .contacts-page {
    &__buttons {
      justify-content: center;
      gap: 20px;
    }
  }
}
